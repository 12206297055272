import HowToUseHachishukVoucherPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/super-discount/Hachisuk/HowToUseHachishukVoucherPopup.vue';
import HowToUseRamiLeviVoucherPopup from '../RamiLevi/HowToUseRamiLeviVoucherPopup';
import HowToUseVictoryVoucherPopup from '../Victory/HowToUseVictoryVoucherPopup.vue';

// eslint-disable-next-line import/prefer-default-export
export const VOUCHERS_PROPS = {
  RAMI_LEVI: {
    title: '״התו המלא״ (רמי לוי)',
    shape: 'rami-shape',
    balanceLink: 'https://ramilevydigital.mltp.co.il/',
    // eslint-disable-next-line max-len
    howToUseLink: 'https://intercom.riseup.co.il/he/articles/6970312-%D7%94%D7%A7%D7%95%D7%A4%D7%95%D7%A0%D7%99%D7%9D-%D7%A9%D7%9C-%D7%A8%D7%99%D7%99%D7%96%D7%90%D7%A4-%D7%9C%D7%A7%D7%A0%D7%99%D7%95%D7%AA-%D7%91%D7%A1%D7%95%D7%A4%D7%A8',
    cancelClassForIntercom: 'cx-cancel-voucher-rami',
    howToUse: HowToUseRamiLeviVoucherPopup,
    singleVoucherWorth: 500,
    discountPercentage: 5,
    trialVoucherWorth: 100,
    // eslint-disable-next-line global-require
    logo: require('@/assets/super-discount/rami/rami-signle-logo.png'),
    name: 'רמי לוי',
    transactionFilter: transaction => transaction.businessName?.includes('רמי לוי'),
  },
  VICTORY: {
    title: '״ויקטורי 100%״',
    shape: 'victory-shape',
    balanceLink: 'https://www.max.co.il/gift-card-transactions/main',
    // eslint-disable-next-line max-len
    howToUseLink: 'https://intercom.riseup.co.il/he/articles/10190995-%D7%94%D7%A7%D7%95%D7%A4%D7%95%D7%A0%D7%99%D7%9D-%D7%A9%D7%9C-%D7%A8%D7%99%D7%99%D7%96%D7%90%D7%A4-%D7%9C%D7%A7%D7%A0%D7%99%D7%95%D7%AA-%D7%91%D7%A8%D7%A9%D7%AA-%D7%95%D7%99%D7%A7%D7%98%D7%95%D7%A8%D7%99',
    cancelClassForIntercom: 'cx-cancel-voucher-victory',
    howToUse: HowToUseVictoryVoucherPopup,
    name: 'ויקטורי',
    discountPercentage: 6,
    transactionFilter: transaction => transaction.businessName?.includes('ויקטורי'),
  },
  HACHISHUK: {
    title: 'החישוק',
    shape: 'hachishuk-shape',
    singleVoucherWorth: 250,
    // eslint-disable-next-line max-len
    howToUseLink: '',
    cancelClassForIntercom: 'cx-cancel-voucher-victory',
    howToUse: HowToUseHachishukVoucherPopup,
    // eslint-disable-next-line global-require
    logo: require('@/assets/super-discount/hachisuk/hachishuk-single.svg'),
    name: 'החישוק',
    discountPercentage: 5,
    balanceLink: 'https://hahishook.com/my-account/woo-wallet/',
    checkBalanceText: 'לטעינת תו ובירור יתרה באתר החישוק',
    transactionFilter: transaction => false,
  },
};

export const VOUCHERS_PURCHASE_CTA_VARIANTS = {
  NONE: 'NONE',
  NEVER_PURCHASED: 'NEVER_PURCHASED',
  DID_NOT_PURCHASE_IN_CURRENT_MONTH: 'DID_NOT_PURCHASE_IN_CURRENT_MONTH',
  PURCHASED_IN_CURRENT_MONTH: 'PURCHASED_IN_CURRENT_MONTH',
  EXCEEDED_LIMIT_IN_CURRENT_MONTH: 'EXCEEDED_LIMIT_IN_CURRENT_MONTH',
};

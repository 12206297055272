<template>
  <div class="transaction-menu-header">
    <div class="ri-bold-body margin-bottom-sm">{{ categoryDisplayName }}
      <span v-if="shouldShowSubcategory" class="ri-body"> · {{ transaction.expense }}</span>
    </div>
    <div class="ri-large-display margin-bottom-s">
      {{ formattedAmount }}<span class="ri-medium-bold-headline"> ₪</span>
    </div>
    <div class="transaction-details ri-body">
      <div v-if="isDifferentOriginalAmount">(סכום מקורי: {{ formattedOriginalAmount | shekel }})</div>
      <div>{{ businessName }}</div>
      <div v-if="actualTransaction">{{ account }}</div>
      <div v-if="actualTransaction">{{ date }}</div>
      <div v-if="isBalanceDateChangedUtilTheEndOfTHeMonth" class="ri-bold-body">
        <div v-if="isIncome">צפויה להגיע עד ה־{{formatDate(cashflowLastDate)}}</div>
        <div v-else>צפוי לרדת עד ה־{{formatDate(cashflowLastDate)}}</div>
      </div>
      <div v-else-if="isBalanced" class="ri-bold-body">
        <div v-if="isIncome">לא צפויה להגיע החודש</div>
        <div v-else>לא צפוי לרדת החודש</div>
        <div>הצפי המקורי היה {{ formattedPredictedAmount | shekel }}</div>
      </div>
      <div v-else-if="predictedWithNoActual" class="ri-bold-body">
        <div v-if="isIncome">צפויה להגיע ב־{{transactionDate}}</div>
        <div v-else>צפוי לרדת ב־{{transactionDate}}</div>
      </div>
    </div>
  </div>
</template>

<script>

import moneyUtils from '@/utils/money';
import cashflowNormalizer from '@/store/utilities/cashflow-normalizer';
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';
import moment from 'moment/moment';
import cashflowHistory from '@/store/utilities/cashflow-history';
import TransactionDisplayUtils from '../../TransactionDisplayUtils';
import cashflowViewConsts from '../../../../../../../../../constants/cashflow-view';

const { categoryNameToTransactionCategory, CATEGORY_NAMES } = cashflowViewConsts;
export default {
  name: 'TransactionMenuHeader',
  mixins: [TransactionDisplayUtils],
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    isPredictedTransaction: {
      type: Boolean,
      required: true,
    },
    categoryName: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('featureFlags', ['showTransactionOriginalAmount']),
    ...mapState('cashflowView', ['presentedBudgetDate']),
    predictedWithNoActual() {
      return this.isPredictedTransaction && !this.transaction.actual;
    },
    actualTransaction() {
      return this.transaction.actual ?? this.transaction;
    },
    account() {
      if (this.predictedWithNoActual) {
        return null;
      }
      return this.getTransactionAccountHeader(this.actualTransaction);
    },
    businessName() {
      return this.predictedWithNoActual ? this.transaction.name : this.actualTransaction.businessName;
    },
    date() {
      if (this.predictedWithNoActual) {
        return null;
      }
      return this.formatDate(this.actualTransaction.transactionDate);
    },
    amount() {
      return this.predictedWithNoActual
        ? this.transaction.predictedAmount
        : this.getTransactionAmountWithoutFraction(this.actualTransaction);
    },
    formattedAmount() {
      if (this.predictedWithNoActual) {
        return moneyUtils.formatAmountWithFraction(0, 1);
      }
      return moneyUtils.formatAmountWithFraction(this.amount, 1);
    },
    categoryDisplayName() {
      return categoryNameToTransactionCategory[this.categoryName]
        ? categoryNameToTransactionCategory[this.categoryName]
        : cashflowNormalizer.TC_DISPLAY_NAME[this.transaction.trackingCategory.name] || this.transaction.trackingCategory.name;
    },
    shouldShowSubcategory() {
      return [CATEGORY_NAMES.FIXED_EXPENSE, CATEGORY_NAMES.FIXED_INCOME].includes(this.categoryName);
    },
    isDifferentOriginalAmount() {
      if (!this.showTransactionOriginalAmount) {
        return false;
      }
      return !_.isNil(this.actualTransaction.originalAmount) && this.actualTransaction.originalAmount !== this.amount;
    },
    formattedOriginalAmount() {
      return moneyUtils.formatAmountWithFraction(this.actualTransaction.originalAmount, 1);
    },
    formattedPredictedAmount() {
      return moneyUtils.formatAmountWithFraction(this.transaction.predictedAmount, 1);
    },
    isBalanced() {
      return this.transaction.balanceDatePassed && this.predictedWithNoActual;
    },
    transactionDate() {
      return this.formatDate(this.transaction.predictedTransactionDate);
    },
    isIncome() {
      return this.transaction.isIncome;
    },
    isBalanceDateChangedUtilTheEndOfTHeMonth() {
      return this.transaction.isBalanceDateChanged && this.predictedWithNoActual && !this.isBalanced;
    },
    cashflowLastDate() {
      return cashflowHistory.getCashflowLastDay(this.presentedBudgetDate);
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('D.M.YY');
    },
  },
};

</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/spacings";

.transaction-menu-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: right;

  .transaction-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: right;
  }
}
</style>

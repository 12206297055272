<template>
  <popup :close-action="this.closeModal">
    <template v-slot:title>
      איך להשתמש בתו?
    </template>
    <template v-slot:content>
      <how-to-use-hachishuk-voucher-explanation />
    </template>
    <template v-slot:footer>
      <div class="ri-large-bold-body full-width full-height link" @click="readMore">
        להסבר המלא
      </div>
    </template>
  </popup>
</template>

<script>
import { mapActions } from 'vuex';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import HowToUseHachishukVoucherExplanation
  from '@/pages/responsive-pages/authenticated/navigation-pages/super-discount/Hachisuk/HowToUseHachishukVoucherExplanation.vue';

export default {
  name: 'HowToUseHachishukVoucherPopup',
  components: {
    HowToUseHachishukVoucherExplanation,
    Popup: BaseUI.Popup,
  },
  created() {
    Segment.trackUserGot('HowToUseHachishukVoucherPopup_Entered');
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    readMore() {
      Segment.trackUserInteraction('HowToUseHachishuk_Voucher_Clicked');
      // eslint-disable-next-line max-len
      window.open('https://intercom.riseup.co.il/he/articles/10994318-%D7%94%D7%97%D7%99%D7%A9%D7%95%D7%A7', '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../super-discount';

</style>

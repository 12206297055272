import _ from 'lodash';
import sumsumApi from '@/api/SumsumApi';

const state = {
  dailyRoutines: [],
  selectedDailyRoutine: null,
  showDailyRoutineViewer: false,
  introDailyRoutine: null,
  doneDailyRoutine: null,
  isIntroDismissed: false,
};

const getters = {
};

const actions = {
  async initDailyRoutine({ commit }) {
    const dailyRoutines = await sumsumApi.getDailyRoutines();
    const introRoutineCard = _.find(dailyRoutines, { type: 'INTRO' });
    const doneRoutineCard = _.find(dailyRoutines, { type: 'DONE' });
    const customerDailyRoutines = _.reject(dailyRoutines, dr => dr.type === 'INTRO' || dr.type === 'DONE');

    commit('setIntroDailyRoutine', introRoutineCard);
    commit('setDoneDailyRoutine', doneRoutineCard);
    commit('setDailyRoutines', customerDailyRoutines);
    commit('setSelectedDailyRoutine', introRoutineCard);
  },
  async dismissDailyRoutine({ commit, state }) {
    const updateDrs = _.reject(state.dailyRoutines, dr => dr.entityId === state.selectedDailyRoutine.entityId);
    commit('setDailyRoutines', updateDrs);
    commit('setSelectedDailyRoutine', updateDrs.length ? updateDrs[0] : state.doneDailyRoutine);
  },
  async dismissIntro({ commit, state }) {
    commit('setIsIntroDismissed', true);
    commit('setSelectedDailyRoutine', state.dailyRoutines.length ? state.dailyRoutines[0] : null);
  },
};

const mutations = {
  setDailyRoutines(state, dailyRoutines) {
    state.dailyRoutines = dailyRoutines;
  },
  setSelectedDailyRoutine(state, dailyRoutine) {
    state.selectedDailyRoutine = dailyRoutine;
  },
  setShowDailyRoutineViewer(state, showDailyRoutineViewer) {
    state.showDailyRoutineViewer = showDailyRoutineViewer;
  },
  setIntroDailyRoutine(state, introDailyRoutine) {
    state.introDailyRoutine = introDailyRoutine;
  },
  setDoneDailyRoutine(state, doneRoutineCard) {
    state.doneDailyRoutine = doneRoutineCard;
  },
  setIsIntroDismissed(state, isIntroDismissed) {
    state.isIntroDismissed = isIntroDismissed;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div class="flex-column full-width">
    <div class="ri-bold-body">בחנות:</div>
    <div class="ri-body">{{explain}}</div>
    <div class="ri-body">
      אפשר להשתמש בכמה תווים בקניה אחת.
    </div>

    <div class="margin-top-m ri-bold-body">בקניות אונליין:</div>
    <div class="full-width padding-right-s ri-body">
      <ul>
        <li>ברגע שמסיימים למלא את הפרטים האישיים ואת פרטי המשלוח, לוחצים ״הבא״ וממשיכים לעמוד של פרטי התשלום.
          שם צריך ללחוץ ״למימוש גיפטקארד וכרטיסי הנחה״.</li>
        <li>לבחור בכרטיס מתנה של ״תו קניה ויקטורי״.</li>
        <li>בשלב הבא, תופיע חלונית שמבקשת להזין פרטי אשראי לתשלום - בחלונית הזו צריך להזין את פרטי התו.
          במקום שבו כתוב מספר כרטיס אשראי - צריך להזין את קוד התו. ולאחר מכן תוקף ומספר CVV.</li>
        <li>בחלונית הבאה צריך להזין שוב CVV ואז ללחוץ ״למימוש״. אחר כך  ללחוץ על ״סיים והמשך לתשלום״.</li>
        <li>בשלב אחרון צריך להוסיף את פרטי כרטיס האשראי שלך, לביטחון.</li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HowToUseVictoryVoucherExplanation',
  components: {
  },
  computed: {
    explain() {
      return 'הקופאי.ת תשאל אותך מה סוג התו - התשובה היא הזנת אשראי ידני (עסקה טלפונית). צריך להקליד במסוף את הקוד (16 ספרות), תוקף, ומספר CVV.';
    },
  },
};
</script>

<style lang="scss" scoped>

ul {
  list-style-position: outside;
  margin: 0;
  padding: 0  2px 0 0 ;
}

li {
  margin-top: 10px;;
}

</style>

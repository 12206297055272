<template>
  <bottom-sheet block-content :variant="variant" @close="closeMenu">
    <template v-slot:header>
      <transaction-menu-header :category-name="categoryName" :transaction="transaction" :is-predicted-transaction="isPredictedTransaction"/>
    </template>
    <callout v-if="isTemp" class="temp-callout" iconPath="icons/temp_transaction_large.svg" title="כדאי לחכות יום-יומיים עם עריכת העסקה.">
      <template v-slot:text>
        <div class="callout-text">
          העסקה לא נקלטה סופית בחברת האשראי, והפרטים שלה עשויים להשתנות. לכן לא בטוח שהפעולות שתעשו עליה כרגע יישמרו.
        </div>
      </template>
    </callout>
    <vouchers-purchase-c-t-a-callout v-else-if="voucherPurchaseCtaVendor" :vendor="voucherPurchaseCtaVendor" @click="closeMenu" />
    <template v-slot:menuItems>
      <bottom-sheet-menu-item v-for="action in actions"
                              :key="action.text"
                              :text="action.text"
                              @click="onClick(action.onClick)"
                              :icon-props="iconAssetToIconName(action.icon)"
                              :show-new-badge="!!action.showNewBadge"/>
    </template>
  </bottom-sheet>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import VouchersSupermarketExpenseDataMixin from '@/mixins/VouchersSupermarketExpenseDataMixin.vue';
import VouchersPurchaseCTACallout
  // eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/VouchersPurchaseCTACallout.vue';
import cashflowViewConsts from '../../../../../../../../../constants/cashflow-view';
import TransactionMenuHeader from './TransactionMenuHeader';

export default {
  name: 'TransactionMenu',
  mixins: [VouchersSupermarketExpenseDataMixin],
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    isPredictedTransaction: {
      type: Boolean,
      required: true,
    },
    categoryName: {
      type: String,
      required: true,
    },
    actions: {
      type: Array,
      required: true,
    },
    isTemp: {
      type: Boolean,
      required: false,
      default: false,
    },
    voucherPurchaseCtaVendor: {
      type: String,
      required: false,
    },
  },
  components: {
    VouchersPurchaseCTACallout,
    TransactionMenuHeader,
    BottomSheet: BaseUI.BottomSheet,
    Callout: BaseUI.Callout,
    BottomSheetMenuItem: BaseUI.BottomSheetMenuItem,
  },
  computed: {
    variant() {
      switch (this.categoryName) {
      case cashflowViewConsts.CATEGORY_NAMES.VARIABLE:
        return 'warning';
      case cashflowViewConsts.CATEGORY_NAMES.EXCLUDED_EXPENSE:
      case cashflowViewConsts.CATEGORY_NAMES.EXCLUDED_INCOME:
        return 'black';
      case cashflowViewConsts.CATEGORY_NAMES.FIXED_EXPENSE:
        return 'fixed';
      case cashflowViewConsts.CATEGORY_NAMES.FIXED_INCOME:
      case cashflowViewConsts.CATEGORY_NAMES.VARIABLE_INCOME:
        return 'success';
      case cashflowViewConsts.CATEGORY_NAMES.FIXED_SAVING:
      case cashflowViewConsts.CATEGORY_NAMES.ONE_TIME_SAVING:
        return 'saving';
      case cashflowViewConsts.CATEGORY_NAMES.TRACKING_CATEGORY:
      default:
        return 'primary';
      }
    },
  },
  methods: {
    closeMenu() {
      this.$emit('close');
    },
    iconAssetToIconName(icon) {
      return { iconName: IconAssetToIconName[icon] ?? 'circle' };
    },
    onClick(actionOnClick) {
      this.closeMenu();
      this.$nextTick(actionOnClick);
    },
  },
};

const IconAssetToIconName = {
  'edits-move': 'low_priority',
  'edits-not-savings': 'low_priority',
  'edits-sub-category': 'sell',
  'edits-exclude': 'tab_move',
  'edits-trash': 'delete',
  'edit-prediction': 'edit',
  comment: 'comment',
  split: 'content_cut',
  'edits-is-savings': 'database',
  'savings-not-fixed': 'sync',
  'savings-not-variable': 'sync_disabled',
  'share-with-member': 'group',
  'update-balance-date': 'calendar_today',
};

</script>

<style scoped lang="scss">

@import '~@riseupil/base-ui/src/scss/riseup-colors';

.temp-callout {
  border-radius: 8px;
  background-color: $riseup_light_blue;
  margin: 24px 24px 0;
  padding: 18px 24px;
  text-align: start;
  font-size: 14px;
  line-height: 20px;
  .tool-tip {
    .icon-wrapper {
      padding-top: 0;
    }
  }
}
</style>

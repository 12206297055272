<template>
  <div class="daily-routine-viewer" v-if="showDailyRoutineViewer">
    <div class="page-wrapper">
      <div class="content">
        <div class="page-content" ref="pageContent">
          <div class="close-action" v-on:click="close">
            <img :src="require(`@/assets/icons/close-white.svg`)"/>
          </div>
          <page-viewer
              v-for="(dailyRoutine, index) in dailyRoutinesToDisplay"
              :key="index"
              class="daily-routine-item"
              ref="dailyRoutineItems"
              content-type="daily-routine"
              :ratio="dailyRoutine.page.ratio"
              :pageId="index"
              :page-content="dailyRoutine.page.content"
              :style="getStyle(index)">
          </page-viewer>
        </div>
        <div v-if=hasCardActions class="buttons flex-row">
          <riseup-button
              :title="firstAction.text"
              :action="() => handleAction(firstAction.action)"
              variant="secondary"
              class="action-button"/>
          <div class="card-text">נשארו עוד {{dailyRoutines.length}}</div>
          <riseup-button
              v-if="secondAction"
              :title="secondAction.text"
              variant="secondary"
              :action="() => handleAction(secondAction.action)"
              class="action-button"/>
        </div>
        <div v-else class="buttons flex-row">
          <div class="card-text" @click=handleNoAction()>{{ firstAction.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import storyLib from '@riseupil/story-lib';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import TransactionMenu
  // eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/TransactionMenu.vue';
import TransactionMixin
  // eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/TransactionMixin.vue';
import ChangeTransactionCategoryPopup
  // eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/ChangeTransactionCategoryPopup.vue';
import EditAmountPopup
  // eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/EditAmountPopup.vue';

export default {
  name: 'DailyRoutineViewer',
  mixins: [TransactionMixin],
  components: {
    PageViewer: storyLib.PageViewer,
    RiseupButton: BaseUI.RiseupButton,
  },
  data() {
    return {
      currentPageIndex: 0,
    };
  },
  computed: {
    ...mapState('dailyRoutines', ['selectedDailyRoutine', 'dailyRoutines', 'showDailyRoutineViewer', 'introDailyRoutine', 'doneDailyRoutine',
      'isIntroDismissed']),
    ...mapGetters('cashflowView', ['trackingCategories']),
    trackingCategory() {
      const dailyRoutineCategoryId = this.selectedDailyRoutine?.transaction
        ? this.selectedDailyRoutine.transaction?.categoryId : this.selectedDailyRoutine.data?.id;
      return this.trackingCategories.find(category => category.categoryId === dailyRoutineCategoryId);
    },
    transaction() {
      return {
        ...this.selectedDailyRoutine.transaction,
        categoryName: this.trackingCategory?.categoryName,
        trackingCategory: this.trackingCategory,
        [this.selectedDailyRoutine.transaction?.isIncome ? 'incomeAmount' : 'billingAmount']: this.selectedDailyRoutine.transaction?.amount,
      };
    },
    categoryName() {
      return this.transaction?.categoryName;
    },
    categoryId() {
      return this.transaction?.categoryId;
    },
    firstAction() {
      return this.selectedDailyRoutine.actions[0];
    },
    hasCardActions() {
      return this.firstAction.action !== 'none';
    },
    secondAction() {
      return this.selectedDailyRoutine.actions.length > 1 ? this.selectedDailyRoutine.actions[1] : null;
    },
    dailyRoutinesToDisplay() {
      return [
        ...(this.isIntroDismissed ? [] : [this.introDailyRoutine]),
        ...this.dailyRoutinesIncludingDoneCard,
      ];
    },
    dailyRoutinesIncludingDoneCard() {
      return [...this.dailyRoutines, this.doneDailyRoutine].slice(0, 4);
    },
  },
  methods: {
    ...mapActions('dailyRoutines', ['dismissDailyRoutine', 'dismissIntro']),
    ...mapMutations('dailyRoutines', ['setShowDailyRoutineViewer']),
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('editTransaction', ['startEditingTransaction']),
    getDailyRoutineTypeToActionPopupMap() {
      const openTransactionBottomMenu = async () => {
        await this.openModal({
          component: TransactionMenu,
          props: {
            actions: this.getActions(),
            categoryName: this.transaction.categoryName,
            transaction: this.transaction,
            isPredictedTransaction: false,
            isTemp: this.transaction.isTemp,
          },
          popupAlignment: 'menu',
        });
      };
      const openSetCategoryModal = async () => {
        await this.openModal({
          component: EditAmountPopup,
          props: {
            expenseCategory: this.trackingCategory.categoryName,
            category: this.trackingCategory?.categories.length > 1
              ? this.trackingCategory.categories[this.currentWeek.weekIndex]
              : this.trackingCategory.categories[0],
          },
        });
      };
      const openChangeCategoryModal = async () => {
        await this.startEditingTransaction({
          transaction: this.transaction,
          categoryName: this.transaction.categoryName,
          categoryId: this.transaction.categoryId,
        });
        await this.openModal({ component: ChangeTransactionCategoryPopup });
      };
      return {
        CATEGORY_EXCEEDED: openSetCategoryModal,
        CATEGORY_ABOUT_TO_EXCEED: openSetCategoryModal,
        EXPENSE_EXCLUSION: openTransactionBottomMenu,
        INCOME_EXCLUSION: openTransactionBottomMenu,
        SIGNIFICANT_FIXED_INCOME_HIGHER_THAN_PREVIOUS: openTransactionBottomMenu,
        NEW_TRANSACTION_CATEGORIZATION: openTransactionBottomMenu,
        MIGHT_BE_FIXED: openChangeCategoryModal,
      };
    },

    close() {
      this.setShowDailyRoutineViewer(false);
    },

    async next() {
      await this.dismissDailyRoutine();
    },

    async handleAction(type) {
      if (type === 'dismiss') {
        await this.next();
      } else {
        await this.showActionPopup();
        // await this.next();
      }
    },

    async handleNoAction() {
      switch (this.selectedDailyRoutine.type) {
      case 'INTRO':
        await this.dismissIntro();
        break;
      case 'DONE':
        await this.close();
        break;
      default:
        await this.next();
      }
    },

    async showActionPopup() {
      const mapper = this.getDailyRoutineTypeToActionPopupMap();
      const flavour = this.selectedDailyRoutine.flavour ? `_${this.selectedDailyRoutine.flavour}` : '';
      await mapper[`${this.selectedDailyRoutine.type}${flavour}`]();
    },

    getStyle(index) {
      if (index === 0) {
        return {
          top: '0',
          transform: 'scale(0.8)',
          zIndex: this.dailyRoutines.length - index,
        };
      }

      const scale = 0.8 ** (index + 1);

      let top = 0;
      let increment = 1.5;

      for (let i = 1; i <= index; i++) {
        top += increment;
        increment *= 0.8;
      }

      return {
        position: 'absolute',
        transform: `scale(${scale}, ${0.8})`,
        width: '100%',
        left: 0,
        top: `-${top}%`,
        zIndex: this.dailyRoutines.length - index,
        transition: 'top 0.5s ease-in-out, width 0.5s ease-in-out',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.daily-routine-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;

  .page-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
  }

  @media (min-width: 430px) {
    .page-wrapper {
      width: 400px;
    }
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #000000b3;

    .close-action {
     position: absolute;
      top: 30px;
      right: 30px;
      z-index: 100;
    }

    .buttons {
      align-items: center;
      justify-content: space-between;
      margin: 0 5px;

      .action-button {
        color: $riseup_white;
        width: 100px;
      }

      .card-text {
        color: $riseup_white;
      }
    }

    .page-content {
      flex-grow: 1;
      position: relative;
    }
  }

  .daily-routine-item {
    border-radius: 24px;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    box-shadow: 0px -4px 11.6px 0px #48233021;
  }
}
</style>

<template>
  <popup :close-action="this.closeModal">
    <template v-slot:title>
      איך להשתמש בתו?
    </template>
    <template v-slot:content>
      <how-to-use-rami-levi-voucher-explanation />
    </template>
    <template v-slot:footer>
      <div class="ri-large-bold-body full-width full-height link" @click="readMore">
        להסבר המלא
      </div>
    </template>
  </popup>
</template>

<script>
import { mapActions } from 'vuex';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import HowToUseRamiLeviVoucherExplanation from './HowToUseRamiLeviVoucherExplanation.vue';

export default {
  name: 'HowToUseRamiLeviVoucherPopup',
  components: {
    Popup: BaseUI.Popup,
    HowToUseRamiLeviVoucherExplanation,
  },
  created() {
    Segment.trackUserGot('HowToUseRamiLeviVoucherPopup_Entered');
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    readMore() {
      Segment.trackUserInteraction('HowToUseRamiLeviVoucher_Clicked');
      // eslint-disable-next-line max-len
      window.open('https://intercom.riseup.co.il/he/articles/10091000-%D7%94%D7%A7%D7%95%D7%A4%D7%95%D7%A0%D7%99%D7%9D-%D7%A9%D7%9C-%D7%A8%D7%99%D7%99%D7%96%D7%90%D7%A4-%D7%9C%D7%A7%D7%A0%D7%99%D7%95%D7%AA-%D7%91%D7%A8%D7%A9%D7%AA-%D7%A8%D7%9E%D7%99-%D7%9C%D7%95%D7%99', '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../super-discount';

</style>

<script>
import BaseUI from '@riseupil/base-ui';
import * as _ from 'lodash';
import cashflowViewConsts from '@/constants/cashflow-view';
import Segment from '@/Segment';
import { mapActions } from 'vuex';
import transactionUtils from '@/utils/transaction';

// eslint-disable-next-line max-len
const MoveTransactionToSaving = () => import('@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/edit-saving-transaction/MoveTransactionToSaving');
// eslint-disable-next-line max-len
const MoveOneTimeSavingTransactionToFixedSaving = () => import('@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/edit-saving-transaction/MoveOneTimeSavingTransactionToFixedSaving');
// eslint-disable-next-line max-len
const MoveOneTimeSavingToVariable = () => import('@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/edit-saving-transaction/MoveOneTimeSavingToVariable');
// eslint-disable-next-line max-len
const ChangeTransactionCategoryPopup = () => import('@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/ChangeTransactionCategoryPopup.vue');
// eslint-disable-next-line max-len
const SplitTransactionPopup = () => import('@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/split-transaction/SplitTransactionPopup.vue');
// eslint-disable-next-line max-len
const AddTransactionComment = () => import('@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/AddTransactionComment.vue');

export default {
  name: 'TransactionMixin',
  /**
   * This mixin expects the following properties to be available in the component:
   * - transaction: Object (required) - The transaction object
   * - categoryName: String (optional) - The name of the category
   * - categoryId: String (optional) - The ID of the category
   */
  computed: {
    imageHeaderProps() {
      return {
        // eslint-disable-next-line global-require
        imgSrc: require('@/assets/subscription/account-settings-header.svg'),
        backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.DARK_GREEN,
        bottomSeparator: false,
        textColor: BaseUI.LayoutConsts.COLOR_VARIANT.WHITE,
        backgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM,
        title: 'הגדרות ופרטי מינוי',
      };
    },
    incomeOrExpense() {
      return transactionUtils.incomeOrExpense(this.transaction);
    },
    isExcludedCategory() {
      return this.categoryName === cashflowViewConsts.CATEGORY_NAMES.EXCLUDED_INCOME
          || this.categoryName === cashflowViewConsts.CATEGORY_NAMES.EXCLUDED_EXPENSE;
    },
    isSaving() {
      return this.transaction.isSaving;
    },
    isExcludedSaving() {
      return this.transaction.isExcludedSaving;
    },
  },
  methods: {
    ...mapActions('editTransaction', ['startEditingTransaction']),
    ...mapActions('modalRootStore', ['openModal']),
    getActions() {
      const actionsMapping = {
        editCategory: {
          text: `להזיז את ה${this.incomeOrExpense}`,
          icon: 'edits-move',
          onClick: () => this.openEditCategory(ChangeTransactionCategoryPopup),
        },
        editIncomeCategory: {
          text: `זו לא ${this.incomeOrExpense} משתנה`,
          icon: 'edits-move',
          onClick: () => this.openEditCategory(ChangeTransactionCategoryPopup),
        },
        moveExcludedBack: {
          text: 'להחזיר לתזרים של החודש',
          icon: 'edits-exclude',
          onClick: () => this.openEditCategory(ChangeTransactionCategoryPopup),
        },
        addComment: {
          text: this.transaction.customerComment ? 'לערוך את ההערה' : 'להוסיף הערה',
          icon: 'comment',
          onClick: () => this.openEditCategory(AddTransactionComment),
        },
        splitTransaction: {
          text: this.splitText(),
          icon: 'split',
          onClick: () => this.openEditCategory(SplitTransactionPopup),
        },
        moveToSaving: {
          icon: 'edits-is-savings',
          onClick: this.openEditTransaction(MoveTransactionToSaving),
          text: 'זו הפקדה לחיסכון!',
        },
        moveToFixedSaving: {
          icon: 'savings-not-fixed',
          onClick: this.openEditTransaction(MoveOneTimeSavingTransactionToFixedSaving),
          text: 'זו הפקדה קבועה לחיסכון',
        },
        notOneTimeSaving: {
          icon: 'edits-not-savings',
          onClick: this.openEditTransaction(MoveOneTimeSavingToVariable),
          text: 'זו לא הפקדה לחיסכון',
        },
        markOrUnmarkExcludedAsSavings: {
          icon: this.isExcludedSaving ? 'edits-not-savings' : 'edits-is-savings',
          onClick: this.markOrUnmarkExcludedAsSavings,
          text: this.isExcludedSaving ? 'זו לא הפקדה לחיסכון' : 'זו הפקדה לחיסכון לא מהתזרים',
        },
        shareWithMemberActions: this.getShareActionPerMember(),
      };
      return this.getActionsHelper(
        this.isExcludedCategory,
        this.isSaving,
        this.categoryName,
        this.transaction.isIncome,
        actionsMapping,
        !!this.transaction.splitFrom || !!this.transaction.splitTransactionSlice,
      );
    },

    getActionsHelper(isExcludedCategory, isSaving, categoryName, isIncome, actionsMapping, isSplit) {
      const actions = this.getActionsOfType(actionsMapping, isExcludedCategory, isIncome, categoryName, isSaving);
      if (isSplit) {
        return _.reject(actions, action => this.savingActions(actionsMapping).includes(action));
      }
      return actions;
    },

    getActionsOfType(actionsMapping, isExcludedCategory, isIncome, categoryName, isSaving) {
      const actionsMap = this.actionsByType(actionsMapping);
      if (isExcludedCategory && isIncome) {
        return actionsMap.excludedIncome;
      }
      if (isExcludedCategory && !isIncome) {
        return actionsMap.excludedExpense;
      }
      if (isIncome) {
        return actionsMap.variableIncome;
      }

      if (categoryName === cashflowViewConsts.CATEGORY_NAMES.TRACKING_CATEGORY) {
        return actionsMap.trackingCategory;
      }

      if (isSaving) {
        return actionsMap.oneTimeSaving;
      }

      return actionsMap.variable;
    },

    actionsByType(actionsMapping) {
      const savingActions = [actionsMapping.moveToSaving];

      return {
        variableIncome: [
          actionsMapping.addComment,
          actionsMapping.editIncomeCategory,
          actionsMapping.splitTransaction,
          ...actionsMapping.shareWithMemberActions,
        ],
        variable: [
          actionsMapping.addComment,
          actionsMapping.editCategory,
          actionsMapping.splitTransaction,
          ...savingActions,
          ...actionsMapping.shareWithMemberActions,
        ],
        trackingCategory: [
          actionsMapping.addComment,
          actionsMapping.editCategory,
          actionsMapping.splitTransaction,
          ...savingActions,
          ...actionsMapping.shareWithMemberActions,
        ],
        excludedIncome: [
          actionsMapping.addComment,
          actionsMapping.moveExcludedBack,
          actionsMapping.splitTransaction,
          ...actionsMapping.shareWithMemberActions,
        ],
        excludedExpense: [
          actionsMapping.addComment,
          actionsMapping.moveExcludedBack,
          actionsMapping.splitTransaction,
          actionsMapping.markOrUnmarkExcludedAsSavings,
          ...actionsMapping.shareWithMemberActions,
        ],
        oneTimeSaving: _.compact([
          actionsMapping.addComment,
          actionsMapping.moveToFixedSaving,
          actionsMapping.splitTransaction,
          actionsMapping.notOneTimeSaving,
          ...actionsMapping.shareWithMemberActions,
        ]),
      };
    },

    savingActions(actionsMapping) {
      return [actionsMapping.moveToSaving, actionsMapping.moveToFixedSaving];
    },

    openEditCategory(component) {
      Segment.trackUserInteraction('EditTransactionClicked', {
        businessName: this.transaction.businessName,
        transactionId: this.transaction.transactionId,
      });
      this.startEditingTransaction({
        transaction: this.transaction,
        categoryName: this.categoryName,
        categoryId: this.categoryId,
      });
      this.openModal({
        component,
      });
    },

    openEditTransaction(popupToOpen) {
      return () => {
        Segment.trackUserInteraction('EditTransactionClicked', {
          businessName: this.transaction.name,
          transactionId: this.transaction.transactionId,
        });
        this.openModal({
          component: popupToOpen,
          props: {
            transaction: this.transaction,
            categoryId: this.categoryId,
            categoryName: this.categoryName,
          },
        });
      };
    },

    splitText() {
      if (this.transaction.splitFrom || this.transaction.splitTransactionSlice) {
        return 'לערוך את הפיצול';
      }
      return this.transaction.incomeAmount ? 'לפצל את ההכנסה' : 'לפצל את ההוצאה';
    },

    getShareActionPerMember() {
      /*
      Each time a component that uses computed properties (via mapGetters) is created,
      Vue sets up watchers on the component's computed properties.
      This is not particularly expensive for a single component,
      but when a customer has many transactions it adds up and creates a performance bottleneck.
       */
      if (this.$store.getters['session/numberOfMembers'] === 1 || !this.$store.getters['featureFlags/enableShareTransaction']) {
        return [];
      }
      return this.$store.getters['session/otherMembers'].map(member => ({
        icon: 'share-with-member',
        onClick: () => {
          const WALinkWithPhoneNumber = `https://wa.me/${member.phone}?text=${this.getShareActionMessageText()}`;
          Segment.trackUserInteraction('ShareTransactionClicked');
          window.open(WALinkWithPhoneNumber, '_blank');
        },
        text: `להתייעץ עם ${member.name} על ה${this.incomeOrExpense}`,
        showNewBadge: true,
      }));
    },
  },
};
</script>

<template>
  <div class="flex-column full-width">
    <div class="margin-top-m ri-bold-body">באתר החישוק:</div>
    <div class="full-width padding-right-s ri-body">
      <ul>
        <li>להיכנס למסך <span class="link" @click="openHachishuk"> ״הארנק שלי״ באתר החישוק.</span></li>
        <li>ללחוץ על כפתור ״טעינה עם רייזאפ״.</li>
        <li>להכניס את מספר התו שקיבלת וללחוץ על ״טעינה״, התו יטען לחשבון וישמר עד הקניה.</li>
        <li>בסיום הקניה בעמוד התשלום סכום התו ירד מסכום הקניה.</li>
      </ul>
    </div>
  </div>
</template>

<script>

import Segment from '@/Segment';

export default {
  name: 'HowToUseHachishukVoucherExplanation',
  methods: {
    openHachishuk() {
      Segment.trackUserInteraction('OpenHachishukWallet_Clicked');
      window.open('https://hahishook.com/my-account/woo-wallet', '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>

@import '~@riseupil/base-ui/src/scss/riseup-colors';

.link {
  text-decoration: underline;
  color: $riseup_blue;
  cursor: pointer;
  font-weight: bold;
}

ul {
  list-style-position: outside;
  margin: 0;
  padding: 0  2px 0 0 ;
}

li {
  margin-top: 10px;
}

</style>

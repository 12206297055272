import { render, staticRenderFns } from "./VouchersPurchaseCTACallout.vue?vue&type=template&id=16cb05c2&scoped=true"
import script from "./VouchersPurchaseCTACallout.vue?vue&type=script&lang=js"
export * from "./VouchersPurchaseCTACallout.vue?vue&type=script&lang=js"
import style0 from "./VouchersPurchaseCTACallout.vue?vue&type=style&index=0&id=16cb05c2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16cb05c2",
  null
  
)

export default component.exports
<template>
  <div v-if="ctaText" class="cta-callout-wrapper padding-ml">
    <callout icon-path="icons/info-icon-blue.svg"
             class="cta-callout">
      <template v-slot:text>
        <div @click="goToVouchersPurchase">
        <div class="callout-text">
          {{ctaText}}
        </div>
          <div class="link">לקניית תווים בהנחה</div>
        </div>
      </template>
    </callout>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import VouchersSupermarketExpenseDataMixin from '@/mixins/VouchersSupermarketExpenseDataMixin.vue';
import Segment from '@/Segment';
import {
  VOUCHERS_PURCHASE_CTA_VARIANTS,
} from '@/pages/responsive-pages/authenticated/navigation-pages/super-discount/my-vouchers/vouchers.consts';
import { mapActions } from 'vuex';

export default {
  name: 'VouchersPurchaseCTACallout',
  mixins: [VouchersSupermarketExpenseDataMixin],
  props: {
    vendor: {
      type: String,
      required: true,
    },
  },
  components: {
    Callout: BaseUI.Callout,
  },
  created() {
    Segment.trackUserGot('VouchersPurchaseCTACallout_Shown', { variant: this.variant, vendor: this.vendor });
  },
  computed: {
    ctaText() {
      switch (this.variant) {
      case VOUCHERS_PURCHASE_CTA_VARIANTS.NEVER_PURCHASED:
        return `אפשר להוזיל את ההוצאה הזאת בעתיד ב-${this.discountPercentage}%`;
      case VOUCHERS_PURCHASE_CTA_VARIANTS.DID_NOT_PURCHASE_IN_CURRENT_MONTH:
        // eslint-disable-next-line max-len
        return `${this.buyingTextPluralOrSingular} ב${this.vendorName} החודש ב-₪${this.vendorsData[this.vendor].transactionsSum}. אפשר להוזיל את ההוצאה הזאת בעתיד ב${this.discountPercentage}%`;
      case VOUCHERS_PURCHASE_CTA_VARIANTS.PURCHASED_IN_CURRENT_MONTH:
        return `כבר ${this.buyingTextPluralOrSingular} ${this.getVoucherTextPluralOrSingular(this.vendor)} החודש, אפשר להמשיך להוזיל את ההוצאות`;
      default:
        return undefined;
      }
    },
    variant() {
      return this.getVouchersPurchaseCTAVariant(this.vendor);
    },
    vendorName() {
      return this.vendorsData[this.vendor].name;
    },
    discountPercentage() {
      return this.vendorsData[this.vendor].discountPercentage;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeAllModals']),
    goToVouchersPurchase() {
      Segment.trackUserGot('VoucherCTACalloutLabel_BuyVoucherClicked', { variant: this.variant, vendor: this.vendor });
      this.closeAllModals();
      this.$router.push({ name: 'SuperDiscountV2' });
    },
  },
};

</script>

<style scoped lang="scss">

@import '~@riseupil/base-ui/src/scss/riseup-colors';

.cta-callout-wrapper {
  width: 100%;

  .cta-callout {
    border-radius: 8px;
    background-color: $riseup_light_blue;
    padding: 18px 24px;
    text-align: start;
    font-size: 14px;
    line-height: 20px;

    .tool-tip {
      .icon-wrapper {
        padding-top: 0;
      }
    }

    .link {
      color: $riseup_blue;
      cursor: pointer;
      font-weight: bold;
      text-decoration: underline
    }
  }
}
</style>

<script>

import _ from 'lodash';
import { mapGetters } from 'vuex';
import {
  ECONOMY_DEFAULT_NAME,
} from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/common/supermarketTransactionsUtils';
import {
  VOUCHERS_PROPS,
  VOUCHERS_PURCHASE_CTA_VARIANTS,
} from '@/pages/responsive-pages/authenticated/navigation-pages/super-discount/my-vouchers/vouchers.consts';

export default {
  name: 'VouchersSupermarketExpenseDataMixin',
  computed: {
    ...mapGetters('cashflowView', ['trackingCategories', 'isPresentingCurrentMonth']),
    ...mapGetters('vouchers', ['customerEverPurchasedVoucher', 'currentMonthCountByVendor']),
    ...mapGetters('session', ['numberOfMembers']),
    supermarketTransactions() {
      const supermarketCategory = this.trackingCategories.find(category => category.categoryName === ECONOMY_DEFAULT_NAME);
      if (!supermarketCategory) {
        return [];
      }

      return _.flatMap(supermarketCategory.categories, subCategory => subCategory.transactions);
    },
    vendorsData() {
      return _.mapValues(VOUCHERS_PROPS, vendor => {
        const transactions = this.supermarketTransactions.filter(vendor.transactionFilter);
        const latestTransaction = _.maxBy(transactions, t => t.transactionDate);
        return {
          ...vendor,
          transactions,
          transactionsSum: Math.round(_.sumBy(transactions, t => t.billingAmount)),
          latestTransactionId: latestTransaction && latestTransaction.transactionId,
        };
      });
    },
    latestTransactionIdsToVendors() {
      return _.chain(this.vendorsData)
        .map((vendor, vendorKey) => ({ latestTransactionId: vendor.latestTransactionId, vendor: vendorKey }))
        .keyBy(v => v.latestTransactionId)
        .mapValues(v => v.vendor)
        .value();
    },
    buyingTextPluralOrSingular() {
      return this.numberOfMembers > 1 ? 'קניתם' : 'קנית';
    },
  },
  methods: {
    getVouchersPurchaseCTAVariant(vendor) {
      const purchasedVouchersCount = this.currentMonthCountByVendor(vendor);
      const { transactions } = this.vendorsData[vendor];
      const transactionsSum = _.sumBy(transactions, t => t.billingAmount);

      if (!this.customerEverPurchasedVoucher(vendor) && transactionsSum >= 100) {
        return VOUCHERS_PURCHASE_CTA_VARIANTS.NEVER_PURCHASED;
      }

      if (purchasedVouchersCount === 0 && transactionsSum >= 100) {
        return VOUCHERS_PURCHASE_CTA_VARIANTS.DID_NOT_PURCHASE_IN_CURRENT_MONTH;
      }

      if (purchasedVouchersCount === 3 && transactionsSum >= 300 && vendor === 'RAMI_LEVI') {
        return VOUCHERS_PURCHASE_CTA_VARIANTS.EXCEEDED_LIMIT_IN_CURRENT_MONTH;
      }

      if (purchasedVouchersCount > 0 && transactionsSum >= 300) {
        return VOUCHERS_PURCHASE_CTA_VARIANTS.PURCHASED_IN_CURRENT_MONTH;
      }

      return VOUCHERS_PURCHASE_CTA_VARIANTS.NONE;
    },
    getVouchersPurchaseCTAVendor(transactionId) {
      return this.isPresentingCurrentMonth && this.latestTransactionIdsToVendors[transactionId];
    },
    getVoucherTextPluralOrSingular(vendor) {
      return this.currentMonthCountByVendor(vendor) > 1 ? 'תווים' : 'תו';
    },
  },
};

</script>

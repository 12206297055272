<template>
  <div class="flex-column full-width">
    <div class="ri-bold-body">בחנות:</div>
    <div class="ri-body">מציגים את התו בקופה</div>

    <div class="margin-top-m ri-bold-body">באתר ״רמי לוי״:</div>
    <div class="full-width padding-right-s ri-body">
      <ul>
        <li>אחרי בחירת המוצרים והמעבר לתשלום, במסך בחירת אמצעי תשלום, לבחור באופציה ״התו המלא (כרטיס/קוד דיגיטלי)״</li>
        <li>המערכת תבקש ממך לבחור כרטיס אשראי לביטחון.</li>
        <li>אחרי בחירת כרטיס אשראי לביטחון, ללחוץ על ״קוד התו המלא הדיגיטלי״.</li>
        <li>להכניס את מספר התו שקיבלת (חשוב לשים לב - המספר בלבד, ללא מקפים), ואת הסכום לחיוב.</li>
        <li>ללחוץ ״שמור״. המערכת תחזיר אותך למסך התשלום, שם יש ללחוץ ״לתשלום עם התו המלא״.</li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HowToUseRamiLeviVoucherExplanation',
  components: {
  },

};
</script>

<style lang="scss" scoped>

ul {
  list-style-position: outside;
  margin: 0;
  padding: 0  2px 0 0 ;
}

li {
  margin-top: 10px;;
}

</style>
